import React from "react"
import TrustBox from "../../../../../features/integrations/components/TrustBox"
import { toTrustBoxProps } from "../../../converters/trustpilot"

interface Props {
  content: any
}

const TrustpilotBoxWrapper = ({ content }: Props) => {
  return <TrustBox {...toTrustBoxProps(content.item.trustpilotBox)} />
}

export default TrustpilotBoxWrapper
